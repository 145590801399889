$allianz-red: #ff1721 !default;
$primary: $allianz-red;

.main {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-button-primary {
    background-color: $primary !important;
    border-color: $primary !important;
    color: white !important;
}

.client-button-primary:focus, .client-button-primary:active {
    background-color: $primary !important;
    border-color: $primary !important;
    color: white;
  }
  
.client-button-primary:hover {
      background-color: $primary !important;
      border-color: $primary !important;
      color: white;
}

.client-radio-button input[type="radio"] {
    border-color: $primary;
    background-color: white;
}

.client-radio-button input[type="radio"]:checked {
    border-color: $primary;
    background-color: $primary;
}